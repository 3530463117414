<template>
  <div
    id="nav"
    class="flex-col w-64 h-screen absolute md:relative overflow-auto" :class="{'hide-menu': hideMenu,'vertical-navigation-mode-over':resize() }"
  >
  <!-- class="md:flex flex-col w-64 h-screen absolute md:relative overflow-auto hidden" :class="{ 'hide-menu': ShowSidebar(),'vertical-navigation-mode-over':resize() }" -->

    <div v-if="user && user.type" class="flex bg-white items-center justify-center sticky top-0 justify-between p-2">

      <router-link class="flex items-center no-active dashbord" to="/">
         <img
          alt="..."
          class="align-middle border-none"
          :src="image"
        />
        <!-- <b
          class="mx-2 font-bold text-lg focus:outline-none font-semibold text-menu"
          :class="$colors.textPrimary"
          >Taslime</b
        > -->
      </router-link>
       <button v-if="resize()"  @click="actionSidebar" class="focus:outline-none flex items-center inline"><i style="font-size: 30px;" class="material-icons text-blue-600">close</i></button>
    </div>
    <div
      class="h-full p-2 pt-4 bg-green-600  text-white"
      :class="$colors.sideBar"
    >
    <!-- v-if="user.type != 'TeleConsultant' && user.type != 'CancelledOrder' && user.type != 'NRP' && user.type != 'Return'" -->
      <menu-link
        v-if="getPermission('dashboard')"
        to="/"
        :text="$t('dashboard')"
        class="link"
        icon="dashboard"
      />
      <menu-link
        v-if="getPermission('warehouses') && getAccess('warehouses', 'update')"
        to="/warehouses"
        :text="$t('warehouses')"
        class="link"
        icon="business"
      />
      <div v-if="['warehouses', 'newWarehouse'].indexOf($route.name) > -1 && getAccess('warehouses', 'create')">
        <menu-link
          to="/warehouses/new"
          :text="$t('new_warehouse')"
          icon="add"
          submenu="true"
        />
        
      </div>
     

      <menu-link
        v-if="getPermission('products') && (getAccess('products', 'create')  || ['TeleConsultant', 'StockManager','NRP','CancelledOrder','ToRemind'].indexOf(user.type) != -1)"
        to="/products"
        :text="$t('products')"
        class="link"
        icon="local_mall"
      />
      <div v-if="['products', 'newProduct'].indexOf($route.name) > -1 && getAccess('products', 'create')">
        <menu-link
          to="/products/new"
          :text="$t('new_product')"
          icon="add"
          submenu="true"
        />
      </div>
      <!--
      <menu-link
        v-if="getPermission('categories')"
        to="/categories"
        text="Categories"
        class="link"
        icon="apps"
      />
      <div v-if="['categories', 'newCategory'].indexOf($route.name) > -1">
        <menu-link
          to="/categories/new"
          text="New category"
          icon="add"
          submenu="true"
        />
        
      </div>
      -->

      <menu-link
        v-if="getPermission('analytics') && ['Seller'].indexOf(user.type) == -1"
        to="/callcenter"
        :text="$t('analytics')"
        class="link"
        icon="trending_up"
      />

      <menu-link
        v-if="getPermission('orders')"
        to="/orders"
        :text="$t('orders')"
        class="link"
        icon="local_grocery_store"
      />
      <div v-if="['orders', 'newOrder'].indexOf($route.name) > -1">
        <menu-link
          to="/orders/new"
          v-if="getAccess('orders', 'create') && ['NRP','CancelledOrder','ToRemind','TeleConsultant'].indexOf(user.type) == -1"
          :text="$t('new_order')"
          icon="add"
          submenu="true"
        />
      </div>

      <menu-link
        v-if="user.type == 'TeleConsultant' || user.type == 'NRP' || user.type == 'CancelledOrder' || user.type== 'ToRemind'"
        to="/orders/confirmation"
        :text="$t('confirmation')"
        icon="add"
      />

      <div v-if="['orders', 'importOrders'].indexOf($route.name) > -1">
        <menu-link
          to="/orders/import"
          v-if="getAccess('orders', 'create') && ['NRP','CancelledOrder','ToRemind','TeleConsultant'].indexOf(user.type) == -1"
          :text="$t('import_orders')"
          icon="add"
          submenu="true"
        />
      </div>
     
      <menu-link
        v-if="getPermission('expeditions')"
        to="/expeditions"
        :text="$t('expeditions')"
        class="link"
        icon="local_airport"
      />
      <div v-if="['expeditions', 'newExpedition'].indexOf($route.name) > -1">
        <menu-link
          to="/expeditions/new"
          v-if="getAccess('expeditions', 'create')"
          :text="$t('new_expedition')"
          icon="add"
          submenu="true"
        />
      </div>

      <menu-link
        v-if="getPermission('status') && getAccess('status', 'create')"
        to="/status"
        :text="$t('status')"
        class="link"
        icon="sync"
      />
      <!-- icon2= group_work -->
      <!-- div v-if="['status', 'newStatus'].indexOf($route.name) > -1">
        <menu-link
          to="/status/new"
          text="New status"
          icon="add"
          submenu="true"
        />
      </div-->

      <menu-link
        v-if="getPermission('users') && getAccess('users', 'create')"
        to="/users"
        :text="$t('users')"
        class="link"
        icon="account_circle"
      />
      <div v-if="['users', 'newUser'].indexOf($route.name) > -1">
        <menu-link to="/users/new" text="New user " icon="add" submenu="true" />
      </div>
      <div v-if="['users', 'historyUser'].indexOf($route.name) > -1 && getPermission('userHistories')">
        <menu-link to="/users/history" text="History" icon="history" submenu="true" />
      </div>

      <menu-link
        v-if="getPermission('roles') && getAccess('roles', 'create')"
        to="/roles"
        :text="$t('roles')"
        class="link"
        icon="lock"
      />
      <div v-if="['roles', 'newRole'].indexOf($route.name) > -1">
        <menu-link to="/roles/new" :text="$t('new_role')" icon="add" submenu="true" />
      </div>

      <!--<menu-link
        v-if="getPermission('users')"
        to="/callcenter"
        text="Call Center"
        class="link"
        icon="call"
      />
      <div v-if="['CallCenter', 'newCall'].indexOf($route.name) > -1">
        <menu-link to="/callcenter/new" text="New " icon="add" submenu="true" />
      </div>
-->
      <menu-link
        v-if="getPermission('teams')"
        to="/teams"
       :text="$t('teams')"
        class="link"
        icon="group"
      />
      <div v-if="['teams', 'newTeam'].indexOf($route.name) > -1">
        <menu-link to="/teams/new" :text="$t('new_team')" icon="add" submenu="true" />
      </div>

      <menu-link
        v-if="getPermission('subscribers')"
        to="/subscribers"
        :text="$t('subscribers')"
        class="link"
        icon="supervisor_account"
      />
      <div v-if="['subscribers', 'newSubscribers'].indexOf($route.name) > -1">
        <menu-link
          to="/subscribers/new"
          :text="$t('new_subscriber')"
          icon="add"
          submenu="true"
        />
      </div>
      <menu-link
        v-if="((getPermission('zones') && getAccess('zones', 'create')) || (getPermission('shippings') && getAccess('shippings', 'create')) ) || (user.type=='Accountant' || user.type=='Return' || (user.type=='Seller' && getAccess('shippings', 'read')))"
        to="#"
        :text="$t('shipping_order')"
        class="link"
        icon="local_shipping"
        :isActive="isActive"
        @myFilter="myFilter"
      />
      <menu-link
        v-if="getPermission('zones') && getAccess('zones', 'create')"
        to="/zones"
        :text="$t('zones')"
        class="link"
        icon="map"
        children="true"
      />
      <div v-if="['zones', 'newZones'].indexOf($route.name) > -1">
        <menu-link
          to="/zones/new"
          :text="$t('new_zone')"
          icon="add"
          class="child"
          submenu="true"
        />
      </div>
      <menu-link
        v-if="getPermission('shippings') && getAccess('shippings', 'create') || (user.type=='Return' || (user.type=='Seller' && getAccess('shippings', 'read')))"
        to="/shippings"
        :text="$t('shippings')"
        class="link"
        icon="map"
        children="true"
      />
      <menu-link
        v-if="getPermission('payments')"
        to="/payments"
        :text="$t('payments')"
        class="link"
        icon="payment"
      />
      <div v-if="['payments', 'newPayments'].indexOf($route.name) > -1 && (getAccess('payments', 'create') || getAccess('payments', 'update'))">
        <menu-link
          to="/payments/new"
          :text="$t('new_payment')"
          icon="add"
          submenu="true"
        />
      </div>
      <menu-link
        v-if="getPermission('charges')"
        to="/charges"
        text="Charges"
        class="link"
        icon="card_giftcard"
      />
      <div v-if="['charges', 'newCharge'].indexOf($route.name) > -1">
        <menu-link
          to="/charges/new"
          text="New charge"
          icon="add"
          submenu="true"
        />
      </div>

        <menu-link
        v-if="getPermission('sourcings')"
        to="/sourcings"
        text="Sourcings"
        class="link"
        icon="train"
      />
      <div v-if="['sourcings', 'newSourcing'].indexOf($route.name) > -1">
        <menu-link
          to="/sourcings/new"
          text="New sourcing"
          icon="add"
          submenu="true"
        />
      </div>

      <menu-link
        v-if="getPermission('settings') && getAccess('settings', 'update')"
        to="/settings"
       :text="$t('settings')"
        class="link"
        icon="settings"
      />
       <menu-link v-if="user.type=='Seller'"
          to="/createstore"
          text="Create Store"
          class="link img"
          img="https://storeno.b-cdn.net/shipsen/10-2021/1633356154211.png"
          imghover="https://storeno.b-cdn.net/shipsen/10-2021/1633356552917.png"
          target="_blank"
        />

        <menu-link
        v-if="getPermission('senders') && getAccess('senders', 'create')"
        to="/senders"
        text="SMS sender"
        class="link img"
        img="https://img.icons8.com/sf-regular/48/ffffff/sms.png"
        imghover="https://img.icons8.com/sf-regular/48/000000/sms.png"
      />

        <menu-link
          to="/orders/api"
          v-if="user.type == 'CEO' || user.type == 'Seller'"
          :text="$t('API')"
          icon="device_hub"
          class="link"
        />

      <!--<menu-link to="/orders" text="Orders" class="link" icon="local_grocery_store"/>
        <div v-if="['Orders','newOrder'].indexOf($route.name) > -1">
              <menu-link to="/orders/new"  text="New order" icon="add" submenu="true"/>
        </div>

        <menu-link to="/discounts" text="Discounts" class="link" icon="money_off"/>
        <div v-if="['Discount','newDiscount'].indexOf($route.name) > -1">
              <menu-link to="/discounts/new"  text="New discount" icon="add" submenu="true"/>
        </div>

        <menu-link to="/shippings" text="Shippings" class="link" icon="local_shipping"/>
        <menu-link to="/invoices" text="Invoices" class="link" icon="receipt"/>

        <menu-link to="/settings" text="Settings" class="link" icon="settings"/>-->
    </div>
  </div>
</template>


<script>
import image from "@/assets/logo.png";
export default {
  props: {
      user: { type: Object},
   },
  data() {
    return {
      login: false,
      token: null,
      isActive: false,
      status: false,
      hideMenu: false,
      image: "https://storeno.b-cdn.net/rmexpressglob/logo.png",
    };
  },
  async mounted() {
    // window.onresize = () => {
    //   if (window.innerWidth > 768)
    //     document.querySelector("#nav").style.display = "flex";
    //   else document.querySelector("#nav").style.display = "none";
    // };
    //this.login=this.$jwtService.verifyToken()
    this.token = this.$jwtService.getToken();
    //await this.getUser();
   if(this.user) this.getAccess("users", "read");
   console.log('this.$store.getters.ShowSidebar;------',this.$store.getters.ShowSidebar)
   
  },
  watch: {
    '$store.state.open': async function(oldVal, newVal) {
        // console.log('new update open mise ajour-----',oldVal);
         this.hideMenu=oldVal;
    },
  },
  methods: {
     ShowSidebar(){
       return  this.$store.getters.ShowSidebar;
    },
    actionSidebar(){
      this.$store.dispatch('actionSidebar');
    },
    resize(){
       return  this.$store.getters.resize;
    },
    myFilter: function () {
      this.isActive = !this.isActive;
      // some code to filter users
    },
    async getStatusUser() {
      if (this.user.status == "active") {
        this.status = true;
      } else {
        this.status = false;
      }
    },
    async getUser() {
      const res = await this.$server.me("users");
      if (res.content) this.user = res.content;
      else this.user = [];
    },
    logout() {
      this.$jwtService.destroyToken();
      location.href = "/";
      //this.$router.push("home");
    },
    getAccess(modelToCheck, accessToCheck) {
      const permissions = this.user.role.permissions;
      for (let i in permissions) {
        let model = permissions[i]["model"];
        let access = permissions[i]["access"];

        if (model == modelToCheck) {
          return access[accessToCheck];
        }
      }
    },
    getPermission(name) {
      if (this.user) {
        const permissions = this.user.role.permissions;
        for (let i in permissions) {
          let model = permissions[i]["model"];
          let access = permissions[i]["access"];
          //   console.log('Modell ',model);
          if (model == name) {
            if (
              access.create ||
              access.read ||
              access.update ||
              access.delete
            ) {
             
              if((this.user.type =='CancelledOrder' || this.user.type== 'ToRemind') && name=='shippings') return false
              else return true;
            } else {
              //console.log("not authorized",model);
              return false;
            }
          }
        }
      }
    },
  },
};
</script>

<style>
.dashbord img {
  width: 125px;
}
.link:hover {
  background-color: white;
  color: #38a169;
  border-radius: 6px;
  /* font-weight: bold; */
}
.img-link img:last-child {
  display: none;  
}
.link:hover img:last-child {
  display: block;  
}
.link:hover img:first-child {
  display: none;  
}
.vertical-navigation-mode-over {
    position: fixed !important;
    transform: translateZ(0);
    left: 0 !important;
    top: 0 !important;
    bottom: 0;
}
#nav {
  transition-duration: .4s;
  transition-timing-function: cubic-bezier(.25,.8,.25,1);
  transition-property: visibility,margin-left,margin-right,transform,width,max-width,min-width;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
  background-color: #ff5b2d;
  position: sticky;
  display: flex; 
  flex-direction: column;
  flex: 1 0 auto;
  top: 0;
  width: 260px;
  min-width: 260px;
  max-width: 260px;
  height: 100vh;
  min-height: 100vh;
  max-height: 100vh;
  z-index: 10;
}
</style>
